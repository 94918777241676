<template>
  <div class="user-verify-status">
    <div class="user-verify-txt">
      <p>{{ $locale["verify_progress_text"] }}</p>
      <p>{{ $locale["verify_progress_time"] }}</p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    verifyOnProgress: function() {
      if (this.$user.identity?.id_front && this.$user.identity?.id_back && this.$user.identity?.face_id) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-verify {
  &-status {
    position: relative;
  }
  &-txt {
    font-size: 130%;
    text-align: center;
  }
}
</style>
